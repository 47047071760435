import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "output", "amount"]

  set20(event) {
    event.preventDefault()
    this.amountTarget.value = 20
    this.outputTarget.textContent = "$" + this.amountTarget.value
  }

  set50(event) {
    event.preventDefault()
    this.amountTarget.value = 50
    this.outputTarget.textContent = "$" + this.amountTarget.value
  }

  set75(event) {
    event.preventDefault()
    this.amountTarget.value = 75
    this.outputTarget.textContent = "$" + this.amountTarget.value
  }

  set100(event) {
    event.preventDefault()
    this.amountTarget.value = 100
    this.outputTarget.textContent = "$" + this.amountTarget.value
  }

  otherValue(event) {
    event.preventDefault()
    this.amountTarget.value = this.amountTarget.value
    this.outputTarget.textContent = "$" + this.amountTarget.value
  }

  recalculate(event) {
    event.preventDefault()
    this.outputTarget.textContent = "$" + this.amountTarget.value
  }
}
