import { Controller } from "stimulus"
import consumer from '../channels/consumer'
import CableReady from 'cable_ready'

export default class extends Controller {
  static targets = ['retreat_volunteer_progress']

  initialize() {
    console.log('Retreat Volunteer Progress Initiated.')

    var progressBar = this.retreat_volunteer_progressTarget

    consumer.subscriptions.create({ channel: 'RetreatVolunteerProgressBarChannel', id: this.progress_bar_id() }, {
      received: data => {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    })

  }

  progress_bar_id() {
    return this.retreat_volunteer_progressTarget.getAttribute('id')
  }  
}
