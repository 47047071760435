try {
  require("trix")
  require("@rails/activestorage").start()
  require("@rails/ujs").start()
  require("channels")
}catch(e) {}

import "bootstrap"
import "../stylesheets/application"

import '@fullcalendar/core'
import '@fullcalendar/daygrid'
import '@fullcalendar/timegrid'
import '@fullcalendar/list'
import '@fullcalendar/moment-timezone'

import 'moment'
import 'moment-countdown'
import 'moment-timezone'

import 'animate.css'
import "cocoon";

require("@rails/actiontext")
import "controllers"

console.log('%cWelcome to Thrive Ministry Hub 😎', 'background: #83cdd9; color: white; padding: 10px 25px; border-radius: 5px;')

// User Time Zone
import jstz from 'jstz'

function setCookie(name, value) {
  var expires = new Date()
  expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000))
  document.cookie = name + '=' + value + ';expires=' + expires.toUTCString()
}

const timezone = jstz.determine();
console.log('⏰ Your time zone is: ' + timezone.name());
setCookie("timezone", timezone.name());

$(document.body).on('hidden.bs.modal, shown.bs.modal, show.bs.modal, hide.bs.modal', function () {
  $('body').css('padding-right','0');
  $('body').css('margin-right','0');
});
