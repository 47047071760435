import { Controller } from "stimulus"
import consumer from '../channels/consumer'
import CableReady from 'cable_ready'

export default class extends Controller {
  initialize() {
    consumer.subscriptions.create({ channel: 'AlongsideProviderPairingChannel' }, {
      received: data => {
        if (data.cableReady) CableReady.perform(data.operations, { emitMissingElementWarnings: false })
      }
    })
  }
}
