import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "output", "amount"]
  static creditCardFeeRate = 0.029; // 2.9% credit card fee rate

  static calculateCCFee(val){
    let val1 = parseFloat(val) || 0
    if($('#credit_card_fee_checkbox').prop('checked')){
      val1 = (val1 + (val1*2.9/100)).toFixed(2)
      $('#amount2').val(val1);
      return val1
    }
    $('#amount2').val(val1);
    return val1
  }

  set20(event) {
    event.preventDefault()
    this.amountTarget.value = 20
    this.outputTarget.textContent = "$" + this.constructor.calculateCCFee(20)
  }

  set50(event) {
    event.preventDefault()
    this.amountTarget.value = 50
    this.outputTarget.textContent = "$" + this.constructor.calculateCCFee(50)
  }
  set75(event) {
    event.preventDefault()
    this.amountTarget.value = 75
    this.outputTarget.textContent = "$" + this.constructor.calculateCCFee(75)
  }

  set100(event) {
    event.preventDefault()
    this.amountTarget.value = 100
    this.outputTarget.textContent = "$" + this.constructor.calculateCCFee(100)
  }

  otherValue(event) {
    event.preventDefault()
    this.amountTarget.value = this.amountTarget.value
    this.outputTarget.textContent = this.constructor.calculateCCFee(this.amountTarget.value)
  }

  recalculate(event) {
    event.preventDefault()

    this.outputTarget.textContent = "$" + this.constructor.calculateCCFee(this.amountTarget.value)
  }

  addCreditCardFee(event) {
    event.preventDefault();
    this.creditCardFee = event.target.checked;
    this.updateOutput();
  }

  updateOutput() {
    let amount = parseFloat(this.amountTarget.value);
    if (isNaN(amount)) {
      amount = 0;
    }

    if (this.creditCardFee) {
      amount *= (1 + this.constructor.creditCardFeeRate); // Add 2.9% to amount
    }

    // Update output only if amountTarget has a valid number
    if (!isNaN(amount)) {
      this.outputTarget.textContent = "$" + amount.toFixed(2);
      $('#amount2').val(amount.toFixed(2));
    }
  }


}
