import { Controller } from "stimulus"
import consumer from '../channels/consumer'
import CableReady from 'cable_ready'

export default class extends Controller {
  static targets = ['conversation']

  initialize() {
    consumer.subscriptions.create({ channel: 'AlongsideTeamChannel', id: this.conversationId() }, {
      received: data => {
        if (data.cableReady) CableReady.perform(data.operations, { emitMissingElementWarnings: false })
      }
    })
  }

  conversationId() {
    return this.conversationTarget.getAttribute('data-id')
  }
}
