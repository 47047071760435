import { Controller} from "stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import luxon2Plugin from '@fullcalendar/luxon2';
import listPlugin from '@fullcalendar/list';
import 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import moment from 'moment-timezone';
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["calendar", "startTime", "endDateTime", "denverStartTimeDisplay", "startTimeDisplay"]

  connect() {
    console.log("✅ Schedule your calendar!");
    let _this = this
    let start_date = document.querySelector('meta[name="start_date"]').getAttribute("content");
    let end_date = document.querySelector('meta[name="end_date"]').getAttribute("content");
    let gathering_id = document.querySelector('meta[name="gathering_id"]').getAttribute("content");
    let timeZoneSelectorEl = document.getElementById('time-zone-selector');
    let meetingDurationSelector = document.getElementById('zoom_meeting_duration');

    let prevent_start_date = moment(start_date).tz("America/Denver").add(1, 'hours').format('YYYY-MM-DD hh:mm:ss');
    let prevent_end_date = moment(end_date).tz("America/Denver").subtract(90, 'minutes').format('YYYY-MM-DD hh:mm:ss');
    let timezone_for_calendar = window.localStorage.getItem("timezone_for_calendar") || "America/Denver";

    let calendar = new Calendar(this.calendarTarget, {
      allDaySlot: false,
      editable: false,
      expandRows: true,
      eventOverlap: false,
      height: 'auto',
      headerToolbar: {
        left: 'title',
        center: 'timeGridWeek,timeGridDay',
        right: 'prev,next'
      },
      initialView: 'timeGrid',
      navLinks: true,
      plugins: [timeGridPlugin, interactionPlugin, momentTimezonePlugin, listPlugin, luxon2Plugin, dayGridPlugin ],
      selectable: true,
      slotMinTime: '00:00:00',
      slotDuration: '00:30:00',
      titleFormat: { year: 'numeric', month: 'long' },
      timeZone: timezone_for_calendar,
      visibleRange: {
        start: start_date,
        end: end_date
      },
      validRange: {
        start: start_date,
        end: end_date
      },
      weekends: true,
      dateClick: function(info) {
        if (moment(info.dateStr) < moment(start_date).add(1, 'hours')) {
          jQuery('#modal-one-on-one-before-schedule').modal('show')
        } else if (moment(info.dateStr) > moment(end_date).subtract(2, 'hours')) {
          jQuery('#modal-one-on-one-after-schedule').modal('show')
        } else if (moment(info.dateStr) < moment()) {
          jQuery('#modal-one-on-one-past').modal('show')
        } else {
          meetingDurationSelector.addEventListener('change', function() {
            _this.endDateTimeTarget.value = moment(info.dateStr).tz("America/Denver").add(this.value, 'minutes')
          });
          _this.denverStartTimeDisplayTarget.textContent = moment(info.dateStr).tz("America/Denver").format('hh:mm a (MM/DD/YYYY)')
          _this.startTimeDisplayTarget.textContent = moment(info.dateStr).format('hh:mm a (MM/DD/YYYY)')
          _this.startTimeTarget.value = moment(info.dateStr).tz("America/Denver")
          _this.endDateTimeTarget.value = moment(info.dateStr).tz("America/Denver").add(meetingDurationSelector.value, 'minutes')
          jQuery('#modal-one-on-one-meeting-schedule').modal('show')
        }
      },

      eventSources: [
        {
          url: "/zoom-meetings.json"
        }
      ],

      events: [
        {
          start: prevent_start_date,
          end: prevent_end_date,
          display: 'inverse-background'
        }
      ]
    })
    calendar.render()

    timeZoneSelectorEl.addEventListener('change', function() {
      window.localStorage.setItem("timezone_for_calendar", this.value);
      calendar.setOption('timeZone', this.value);
    });
  }
}
